.item-card__cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(var(--defaultMargin) * 1.5);
}

.item-card__header > h3 {
    margin-bottom: calc(var(--defaultMargin) / 2);
    cursor: pointer;
}
.item-card__header{
    max-width: 30rem;
}

.item-card__image__cont {
    height: 30vh;
    width: 30vw;
    overflow: hidden;
    box-shadow: 0 0 4px rgba(46, 71, 118, 0.3),
    0 0 5px rgba(82, 36, 120, 0.31);
    border-radius: 3px;
}

.item-card__image {
    width: 100%;
    object-fit: cover;
    object-position: top;
    cursor: pointer;

}

@media screen and (max-width: 992px) {
    .item-card__cont {
        margin-right: calc(var(--defaultMargin) / 3);
        margin-left: calc(var(--defaultMargin) / 3);

    }

    .item-card__image__cont {
        height: 14vh;
        width: 60vw;
        overflow: hidden;
        margin-bottom: calc(var(--defaultMargin) / 4);
    }


    .item-card__header > h3 {
        font-size: var(--fontSize-large);
        margin-bottom: 0.3em;
        letter-spacing: 0.08rem;
    }

    .item-card__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;


    }

}