.about-intro-section {
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;

    margin-bottom: 6rem;
}

.about-text{
    font-size: var(--fontSize-regular);
    margin-bottom: 3rem;
}
.about-title {
    margin-bottom: 3rem;
    position: relative;
    color: white;
    font-weight: bold;
}

.about-title::after {
    content: "";
    position: absolute;
    width: 3rem;
    height: 0.3rem;
    background-color: var(--colorPrimary);
    bottom: -0.8rem;
    left: -1.5rem;
}
.about-subtitle{
    opacity: 60%;
}
.about-secondary-title{
 margin-bottom: calc(var(--defaultMargin) / 4);
}

