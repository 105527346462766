.navbar {
  --padding: 0.8em;
  display: grid;
  grid-template-columns: calc(var(--pageMargin) - var(--padding)) auto calc(var(--pageMargin) - var(--padding));
  padding: 0.8em;
  font-size: var(--fontSize-medium);
  position: sticky;
  top: 0;
  z-index: 5000;
  background: linear-gradient(90deg, var(--backGroundColor) 0%, var(--backGroundColorTwo) 100%);
}


.HashLink {
  text-decoration: none;
}

.left-container {
  grid-area: 1 / 1 / 2 / 2;
  padding: 0.5em;
  z-index: 9999;
}

.center-container {
  grid-area: 1 / 2 / 2 / 2;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}

.right-container {
  grid-area: 1 / 3 / 2 / 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;
}

.nav__name {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: var(--fontSize-medium);
  align-self: center;
}

.nav__name:hover {
  transform: scale(1.05);
}

.nav__p {
  color: transparent;
  background-image: linear-gradient(to right, var(--colorPrimary) 50%, white 50%);
  background-clip: text;
  -webkit-background-clip: text;
  transition: background-position 0.2s ease-out;
  background-size: 200% 100%;
  background-position: 100% 0;
  font-weight: 800;
  font-size: var(--fontSize-medium);
  align-self: center;
  text-decoration: none;
}


.nav__p:hover {
  background-position: 0 0;
  text-decoration: none;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger-button {
  width: 40px;
  height: 20px;
}

.close-button {
  width: 20px;
  height: 20px;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
  transition: all 0.3s ease;
}

.mobile-menu {
  display: none;
  margin-top: 5em;
}

@media screen and (max-width: 768px) {
  .center-container {
    display: none;
    overflow: hidden;
  }

  .right-container {
    justify-content: flex-end;
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav__p{
    align-self: unset;
  }
  .navbar{
    padding: 0.2em 0.8em;
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: -37%;
    right: 25%;
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, var(--backGroundColor) 0%, var(--backGroundColorTwo) 100%);
    z-index: 5001;
    padding: 1.5em;
    gap: 2em;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 32%;
    animation: expandMenu 1s ease forwards;
    border-radius: 50%;
    overflow: hidden;
  }
}

@keyframes slideInMenu {
  from {
    transform: translate(100%, -100%);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes expandMenu {
  from {
    width: 0;
    height: 0;
    opacity: 0;
  }

  to {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}