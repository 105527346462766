.skills-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
}

.skills-title {
    line-height: 1.5rem;
    margin-bottom: 3rem;
    position: relative;
    color: white;
    font-weight: bold;
}

.skills-title::after {
    content: "";
    position: absolute;
    width:3rem;
    height: 0.3rem;
    background-color: var(--colorPrimary);
    bottom: -0.8rem;
    left: -1.5rem;
}

.skills-list {
    display: flex;
    flex-direction: column;
}

.skills-item {
    display: flex;
    flex-direction: column;
    align-items: center;
   font-size: var(--fontSize-medium);
}

.skills-info {
    display: flex;
    align-items: flex-start;
}

.skills-name {
    font-size: var(--fontSize-medium);
    margin-bottom: 1rem;
}

.empty-circle {
    width: 10px;
    height: 10px;
    border: 2px solid var(--colorPrimary);
    border-radius: 50%;
    margin-bottom: 1rem;
    opacity: 50%;
}
