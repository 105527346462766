.intro-section {
    margin: calc(var(--defaultMargin) * 2) 0;
    /* text-align: justify; */
    text-align: center;
    line-height: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: calc(var(--defaultMargin)/3);
}

.highlight-text {
    font-size: var(--fontSize-XL);
}

@media screen and (max-width: 992px) {
    .intro-section {
        margin:  0;
    }
    .highlight-text{
        font-size: var(--fontSize-large);
        font-weight: 600;
    }
}