.project-header {
    margin-top: calc(var(--defaultMargin) * 2);
}

.project-header > h6 {
    margin-top: calc(var(--defaultMargin) / 4);
    opacity: 60%;
}

.project-header--image-cont {
    margin-top: calc(var(--defaultMargin) / 1.5);
    width: 100%;
    height: 40vh;
    overflow: hidden;
}

.project-header--image-cont img, .project--image-cont img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.project-description {
    margin-top: calc(var(--defaultMargin) / 1.5);
    opacity: 80%;
    text-align: justify;
}

.project-details {
    margin-top: calc(var(--defaultMargin) / 2);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2vw;
}

.project-details--header {
    font-weight: bold;
    font-size: var(--fontSize-medium);

}

.project-details--header + p {
    margin-bottom: calc(var(--defaultMargin) / 3);
}

.project--image-cont {
    margin-top: calc(var(--defaultMargin) / 1.5);
    width: 100%;
}

.project--image-cont img {
    margin-bottom: calc(var(--defaultMargin) / 4);
}

.mobile-img__cont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    width: 100%;
}

.mobile-img__cont img {
    width: 100%;
}

.project-details--buttons-container {
    margin-top: calc(var(--defaultMargin) / 2);
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 992px) {
    .project-header {
        margin-top: calc(var(--pageMargin)/2);
    }
    .project-description{
        margin-top: calc(var(--pageMargin));
    }
    .project-details--buttons-container{
        flex-direction: column;
        row-gap: 2rem;
        align-items: center;
    }
    .project-header--image-cont {
        margin-top: calc(var(--defaultMargin) / 2);
        height: 20vh;
    }
}